import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Chris Miller</h2>
        <p><a href="mailto:chris.r.miller@gmail.com">chris.r.miller@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Chris. I like building things.
        I work for <a href="https://saatva.com/">Saatva Mattress</a> and
        run a few small sites like <a href="https://chrisrmiller.com">chrisrmiller.com</a>. Before Saatva I was
        at <a href="https://netspend.com">Netspend</a>
        , <a href="https://keyingredient.com">Key Ingredient</a>
        , <a href="https://dodd.ohio.gov">State of Ohio DODD</a>.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Chris Miller <Link to="/">chrisrmiller.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
